@use '../../../node_modules/@bds/ui/assets/scss/themes/variant' as variant;
@use '../../../node_modules/@bds/ui/assets/scss/core/mixins/functions' as bds;
@use '../../../node_modules/@bds/ui/assets/scss/themes/dark' as dark;
@use '../../../node_modules/@bds/ui/assets/scss/core/variables/variables' as vars;

$variant-theme: variant.$variant-theme;
$dark-theme: dark.$theme;

$danger: bds.get-theme-color-prop($variant-theme, danger, 500);
$warning: bds.get-theme-color-prop($variant-theme, warning, 500);
$info: bds.get-theme-color-prop($dark-theme, primary, 500);
$bourque-blue-barely-light: vars.$bourque-blue-barely-light;

$jeopardized-shipment-red: $danger;
$jeopardized-shipment-orange: $warning;
$jeopardized-shipment-blue: $info;

.shipment-flag-icon-jeopardy {
    --fa-primary-color: black;
    --fa-primary-opacity: 0.1;
    --fa-secondary-color: black;
    --fa-secondary-opacity: 0.1;

    &.flagged-shipment {
        --fa-primary-color: #{$jeopardized-shipment-orange};
        --fa-primary-opacity: 1;
        --fa-secondary-color: #{$jeopardized-shipment-red};
        --fa-secondary-opacity: 1;
    }
}
.shipment-flag-icon-hotlist-general {
    --fa-primary-color: black;
    --fa-primary-opacity: 0.1;
    --fa-secondary-color: black;
    --fa-secondary-opacity: 0.1;

    &.flagged-shipment {
        --fa-primary-color: #{$jeopardized-shipment-orange};
        --fa-primary-opacity: 1;
        --fa-secondary-color: #{$jeopardized-shipment-red};
        --fa-secondary-opacity: 1;
    }
}

.shipment-flag-icon-hotlist-mechanical {
    --fa-primary-color: black;
    --fa-primary-opacity: 0.1;
    --fa-secondary-color: black;
    --fa-secondary-opacity: 0.1;

    &.flagged-shipment {
        --fa-primary-color: #{$jeopardized-shipment-red};
        --fa-primary-opacity: 1;
        --fa-secondary-color: #{$jeopardized-shipment-red};
        --fa-secondary-opacity: 0.4;
    }
}
.shipment-flag-icon-hotlist-lease {
    --fa-primary-color: black;
    --fa-primary-opacity: 0.1;
    --fa-secondary-color: black;
    --fa-secondary-opacity: 0.1;

    &.flagged-shipment {
        --fa-primary-color: #{$jeopardized-shipment-orange};
        --fa-primary-opacity: 0.75;
        --fa-secondary-color: #{$jeopardized-shipment-orange};
        --fa-secondary-opacity: 1;
    }
}
.shipment-flag-icon-trouble {
    --fa-primary-color: white;
    --fa-primary-opacity: 0;
    --fa-secondary-color: black;
    --fa-secondary-opacity: 0.1;

    &.flagged-shipment {
        --fa-primary-color: white;
        --fa-primary-opacity: 0;
        --fa-secondary-color: #{$jeopardized-shipment-red};
        --fa-secondary-opacity: 1;
    }
}
.shipment-flag-icon-interface-error {
    --fa-primary-color: white;
    --fa-primary-opacity: 0;
    --fa-secondary-color: black;
    --fa-secondary-opacity: 0.1;

    &.flagged-shipment {
        --fa-primary-color: white;
        --fa-primary-opacity: 0;
        --fa-secondary-color: #{$jeopardized-shipment-orange};
        --fa-secondary-opacity: 1;
    }
}

.flag-icon-question-mark {
    --fa-primary-color: black;
    --fa-primary-opacity: 0.1;
    --fa-secondary-color: black;
    --fa-secondary-opacity: 0.1;

    &.flagged {
        --fa-primary-color: black;
        --fa-primary-opacity: 0.75;
        --fa-secondary-color: black;
        --fa-secondary-opacity: 1;
    }
}

.flag-icon-generic-warning {
    --fa-primary-color: white;
    --fa-primary-opacity: 0.1;
    --fa-secondary-color: black;
    --fa-secondary-opacity: 0.1;

    &.flagged {
        --fa-primary-color: black;
        --fa-primary-opacity: 0.75;
        --fa-secondary-color: #{$jeopardized-shipment-orange};
        --fa-secondary-opacity: 1;
    }
}

.shipment-flag-comment {
    --fa-primary-color: black;
    --fa-primary-opacity: 0.1;
    --fa-secondary-color: black;
    --fa-secondary-opacity: 0.1;
}
.shipment-flag-comment-0 {
    --fa-primary-color: black;
    --fa-primary-opacity: 0.075;
    --fa-secondary-color: black;
    --fa-secondary-opacity: 0.1;
}
.shipment-flag-comment-l1 {
    --fa-primary-color: #{$bourque-blue-barely-light};
    --fa-primary-opacity: 1;
    --fa-secondary-color: #{$bourque-blue-barely-light};
    --fa-secondary-opacity: 1;
}
.shipment-flag-comment-l2 {
    --fa-primary-color: white;
    --fa-primary-opacity: 1;
    --fa-secondary-color: white;
    --fa-secondary-opacity: 1;
}
.shipment-flag-comment-m {
    --fa-primary-color: #{$jeopardized-shipment-orange};
    --fa-primary-opacity: 1;
    --fa-secondary-color: #{$jeopardized-shipment-orange};
    --fa-secondary-opacity: 1;
}
.shipment-flag-comment-h {
    --fa-primary-color: white;
    --fa-primary-opacity: 1;
    --fa-secondary-color: #{$jeopardized-shipment-red};
    --fa-secondary-opacity: 1;
}
.shipment-flag-comment-sd {
    --fa-primary-color: #{$jeopardized-shipment-red};
    --fa-primary-opacity: 1;
    --fa-secondary-color: #{$jeopardized-shipment-red};
    --fa-secondary-opacity: 0.4;
}
